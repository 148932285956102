import { FC } from 'react';
import { styled } from '@mui/material';
import { Button } from '@mui/material';

const StyledAddressesButton = styled(Button)(() => ({
  width: '100%',
  height: '44px',
  marginTop: '24px',
  backgroundColor: '#FFE600',
  fontWeight: 'bold',
  textTransform: 'none',
  fontSize: '14px',
  border: '1px solid #2E2E38',
  borderRadius: '0px',
  '&:hover': {
    background: 'black',
    color: 'white'
  },
  '& span': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  '&:disabled': {
    backgroundColor: '#EAEAF2',
    color: '#747480'
  }
}));

interface DeriveAddressesButtonProps {
  disabled: boolean;
  handleAddressDerivation: () => void;
}

const DeriveAddressesButton: FC<DeriveAddressesButtonProps> = ({
  disabled,
  handleAddressDerivation
}): JSX.Element => {
  return (
    <StyledAddressesButton
      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      variant="outlined"
      disabled={disabled}
      onClick={handleAddressDerivation}>
      Derive addresses
    </StyledAddressesButton>
  );
};

export default DeriveAddressesButton;
