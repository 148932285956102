import { Api as api } from '../../utils/axios';
import { Result } from '../../core/Result';
import { IBlockService } from './ICryptoService';

export class BlockService implements IBlockService {
  async getLatestBlockHeight(network): Promise<Result<any>> {
    try {
      const response = await api({
        'Content-Type': 'application/json'
      }).get(`networks/block-height?network=${network}`);
      return Result.ok<any>(response.data);
    } catch (error: any) {
      return Result.fail<any>(error?.message);
    }
  }
}
