import { FC } from 'react';
import { Box, Typography } from '@mui/material';

const Maintenance: FC = (): JSX.Element => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#f5f5f5'
      }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          p: 4,
          backgroundColor: 'white',
          borderRadius: 4,
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)'
        }}>
        <Typography variant="h5" component="h1" align="center" mt={2}>
          Maintenance in Progress
        </Typography>
        <Typography variant="body1" align="center" mt={2}>
          We apologize for the inconvenience. The website is currently undergoing maintenance.
          Please check back later.
        </Typography>
      </Box>
    </Box>
  );
};
export default Maintenance;
