import React, { FC } from 'react';
import { Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import { OverAllStatus } from '../shared/models/Jobs';
interface WorkStatusToastProps {
  status: number;
}
const WorkStatusToast: FC<WorkStatusToastProps> = ({ status }): JSX.Element => {
  return (
    <Box
      sx={
        status === OverAllStatus.INVALID_TEMPLATE ||
        status === OverAllStatus.INVALID_CONTENT ||
        status === OverAllStatus.EMPTY_FILE
          ? {
              backgroundColor: '#ED6500',
              borderRadius: '20px',
              padding: '0 5px 0 5px',
              paddingTop: '6px',
              textAlign: 'center',
              width: '112px',
              height: '24px',
              fontSize: '12px'
            }
          : status === OverAllStatus.INPROGRESS
          ? {
              padding: '15px 0px 15px 0px'
            }
          : status === OverAllStatus.COMPLETED
          ? {
              backgroundColor: '#168736',
              color: 'white',
              borderRadius: '20px',
              padding: '2px 15px 2 12px',
              paddingTop: '6px',
              textAlign: 'center',
              width: '77px',
              height: '24px',
              fontSize: '12px'
            }
          : status === OverAllStatus.FAILED
          ? {
              backgroundColor: '#B9251C',
              color: 'white',
              borderRadius: '20px',
              padding: '2px 15px 2 12px',
              paddingTop: '6px',
              textAlign: 'center',
              width: '57px',
              height: '24px',
              fontSize: '12px'
            }
          : {}
      }>
      {status === OverAllStatus.INVALID_TEMPLATE ? (
        'Invalid Template'
      ) : status === OverAllStatus.INVALID_CONTENT ? (
        'Invalid Content'
      ) : status === OverAllStatus.INPROGRESS ? (
        <LoadingButton
          style={{ border: '1px solid #2E2E38', color: '#2E2E38' }}
          sx={{
            borderRadius: '20px',
            fontSize: '12px',
            textTransform: 'none',
            padding: '0 20px 0 20px',
            fontWeight: '400',
            height: '24px'
          }}
          loading
          loadingPosition="start"
          startIcon={<SaveIcon />}
          variant="outlined">
          In Progress
        </LoadingButton>
      ) : status === OverAllStatus.COMPLETED ? (
        'Complete'
      ) : status === OverAllStatus.FAILED ? (
        'Failed'
      ) : status === OverAllStatus.EMPTY_FILE ? (
        'Empty Input File'
      ) : (
        ''
      )}
    </Box>
  );
};

export default WorkStatusToast;
