import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { ResponseError } from '../../core/IError';
import { fileService } from '../../services/file';
import { IFileExecute } from '../../models/Files';

interface IFileSliceState {
  value: string;
  pending: boolean;
  error?: string;
}

const initialState: IFileSliceState = {
  value: '',
  pending: true,
  error: ''
};

export const deriveAddress = createAsyncThunk<
  any | string,
  IFileExecute,
  { rejectValue: ResponseError }
>('/file/deriveAddress', async (fileExceute, thunkApi) => {
  const response = await fileService.deriveAddress(fileExceute);
  if (response.isSuccess) {
    return response.getValue();
  }
  return thunkApi.rejectWithValue({ message: response.errorValue().toString() });
});

export const fileSlice = createSlice({
  name: 'fileSlice',
  initialState,
  reducers: {},
  extraReducers: (reducers) => {
    reducers
      .addCase(deriveAddress.pending, (state) => {
        state.pending = true;
        state.error = '';
      })
      .addCase(deriveAddress.rejected, (state, { payload }) => {
        state.error = payload?.message.toString();
        state.pending = false;
      })
      .addCase(deriveAddress.fulfilled, (state, { payload }) => {
        state.pending = false;
        state.value = payload;
        state.error = '';
      });
  }
});

export const selectFileSlice = (state: RootState) => {
  return state.fileSlice;
};

// export the reducers
export default fileSlice.reducer;
