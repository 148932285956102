import { createTheme } from '@mui/material';

const customTheme = createTheme({
  palette: {
    primary: {
      main: '#2E2E38'
    }
  }
});

export default customTheme;
