import { Api as api } from '../../utils/axios';
import { Result } from '../../core/Result';
import { IJobService } from './IJobService';
import { Jobs } from '../../models/Jobs';

export class JobService implements IJobService {
  async getAllJobs(): Promise<Result<Jobs[]>> {
    try {
      const response = await api().get('/jobs');
      return Result.ok<Jobs[]>(response.data);
    } catch (error: any) {
      return Result.fail<any>(error?.message);
    }
  }
}
