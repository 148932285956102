import { FC, useEffect, SetStateAction, useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, Avatar, styled, Menu, MenuItem, Divider } from '@mui/material';
import { clearCookies, getCookie, isAuthenticated, stringAvatar } from '../shared/utils';
import { useAuth0 } from '@auth0/auth0-react';
import { user as UserService } from '../shared/services/users';

interface NavbarProps {
  isBTCTabClicked: boolean;
  setIsBTCTabClicked: React.Dispatch<SetStateAction<boolean>>;
  isETHTabClicked: boolean;
  setIsETHTabClicked: React.Dispatch<SetStateAction<boolean>>;
  isLTCTabClicked: boolean;
  setIsLTCTabClicked: React.Dispatch<SetStateAction<boolean>>;
  isTabEnabled: boolean;
}
const UserAvatar = styled(Avatar)(() => ({
  width: 38,
  height: 38,
  fontFamily: 'EYInterstate',
  fontStyle: 'normal',
  fontWeight: 300,
  fontSize: 14,
  position: 'absolute',
  right: 12,
  marginTop: 4,
  color: '#2E2E38',
  cursor: 'pointer'
}));

const StyledMenuItem = styled(MenuItem)(() => ({
  fontFamily: 'EYInterstate',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: 12,
  lineHeight: 2,
  textTransform: 'uppercase',
  color: '#747480'
}));

const LinkDisable = {
  color: '#FFFFFF',
  textDecoration: 'none',
  pointerEvents: 'none',
  opacity: '0.4'
};

const Navbar: FC<NavbarProps> = ({
  isBTCTabClicked,
  setIsBTCTabClicked,
  isETHTabClicked,
  setIsETHTabClicked,
  isLTCTabClicked,
  setIsLTCTabClicked,
  isTabEnabled
}): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const cookie = getCookie() || {};
  const { logout } = useAuth0();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);

  const handleLogout = async () => {
    const accesstoken = isAuthenticated();
    if (accesstoken) {
      await UserService.logout(accesstoken);
    }
    clearCookies();
    logout({ logoutParams: { returnTo: window.location.origin, federated: true } });
    setIsBTCTabClicked(false);
    setIsETHTabClicked(false);
    setIsLTCTabClicked(false);
    setAnchorEl(null);
  };
  const handleBTCTabClick = () => {
    setIsBTCTabClicked(true);
    setIsETHTabClicked(false);
    setIsLTCTabClicked(false);
  };

  const handleETHTabClick = () => {
    setIsETHTabClicked(true);
    setIsBTCTabClicked(false);
    setIsLTCTabClicked(false);
  };

  const handleLTCTabClick = () => {
    setIsLTCTabClicked(true);
    setIsBTCTabClicked(false);
    setIsETHTabClicked(false);
  };

  // useEffect(() => {
  //   !isETHTabClicked && setIsBTCTabClicked(true);
  // }, [isETHTabClicked, setIsBTCTabClicked]);

  useEffect(() => {
    if (!isETHTabClicked && !isLTCTabClicked && !isBTCTabClicked) {
      setIsBTCTabClicked(true);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Box
      component="nav"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: '#2e2e38',
        fontSize: '14px'
      }}>
      <Link
        to="/"
        style={!isTabEnabled ? LinkDisable : { color: '#FFFFFF', textDecoration: 'none' }}>
        <Box
          onClick={handleBTCTabClick}
          sx={{
            textDecorationLine: 'none',
            padding: '12px',
            ...(isBTCTabClicked &&
              isTabEnabled && {
                borderBottom: '3px solid #FFE600'
              })
          }}>
          Bitcoin Address Derivation
        </Box>
      </Link>
      <Link
        to="/ethereum"
        style={!isTabEnabled ? LinkDisable : { color: '#FFFFFF', textDecoration: 'none' }}>
        <Box
          onClick={handleETHTabClick}
          sx={{
            textDecorationLine: 'none',
            padding: '12px',
            ...(isETHTabClicked &&
              isTabEnabled && {
                borderBottom: '3px solid #FFE600'
              })
          }}>
          Ethereum Address Derivation
        </Box>
      </Link>
      <Link
        to="/litecoin"
        style={!isTabEnabled ? LinkDisable : { color: '#FFFFFF', textDecoration: 'none' }}>
        <Box
          onClick={handleLTCTabClick}
          sx={{
            textDecorationLine: 'none',
            padding: '12px',
            ...(isLTCTabClicked &&
              isTabEnabled && {
                borderBottom: '3px solid #FFE600'
              })
          }}>
          Litecoin Address Derivation
        </Box>
      </Link>
      <UserAvatar
        onClick={handleClick}
        {...stringAvatar(cookie.first_name + ' ' + cookie.last_name)}
      />
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={() => setAnchorEl(null)}
        PaperProps={{
          elevation: 0,
          sx: {
            position: 'absolute',
            width: 180,
            height: 100,
            left: 2,
            top: 0,
            background: '#FFFFFF',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            mt: 1
          }
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
        <StyledMenuItem disabled>{cookie.first_name + ' ' + cookie.last_name}</StyledMenuItem>
        <Divider />
        <StyledMenuItem onClick={handleLogout}>Sign out</StyledMenuItem>
      </Menu>
    </Box>
  );
};

export default Navbar;
