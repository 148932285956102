export const SUPPORTED_ASSETS = {
  ETH: 'ETH',
  BTC: 'BTC',
  LTC: 'LTC'
};
export const SUPPORTED_ASSETS_NETWORK_NAME = {
  ETH: 'Ethereum',
  BTC: 'Bitcoin',
  LTC: 'Litecoin'
};
export const firstNameEmptyErrorMessage = '* First name is required';
export const lastNameEmptyErrorMessage = '* Last name is required';
export const emailEmptyErrorMessage = '* Email is required';
export const emailInvalidErrorMessage = '* Invalid email address';
export const passwordEmptyErrorMessage = '* Password is required';
export const passwordInvalidErrorMessage =
  '* Password must contain at least 10 characters, 1 lower case, 1 upper case, 1 number and 1 special character';
export const passwordConfirmationEmptyErrorMessage = '* Password confirmation is required';
export const passwordConfirmationInvalidErrorMessage = '* Passwords did not match';
