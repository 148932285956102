import { Suspense, FC } from 'react';
import { ThemeProvider } from '@mui/material';
import { Provider } from 'react-redux';
import Dashboard from './components/Dashboard';
import customTheme from './shared/theme/customTheme';
import { store } from './shared/redux/store';
import { BrowserRouter as Router, useHistory } from 'react-router-dom';
import { Auth0Provider, Auth0ProviderOptions } from '@auth0/auth0-react';
import { PropsWithChildren } from 'react';
import SessionTimeout from './components/SessionTimeout';
import { user as UserService } from '../src/shared/services/users';
import Maintenance from './components/Maintenance';

const App: FC = (): JSX.Element => {
  const history = useHistory();

  const maintenance_mode: boolean =
    (window as any)['platformConfig']?.REACT_APP_MAINTENANCE_MODE ?? false;

  const Auth0ProviderWithRedirectCallback = ({
    children,
    ...props
  }: PropsWithChildren<Auth0ProviderOptions>) => {
    return <Auth0Provider {...props}>{children}</Auth0Provider>;
  };

  const auth0domain = (window as any)['platformConfig']?.Auth0_Domain;
  const auth0clientid = (window as any)['platformConfig']?.Auth0_ClientId;
  const auth0Audience = (window as any)['platformConfig']?.Auth0_Audience;
  const auth0RedirectUri = `${window.location.origin}/dashboard`;

  const onRedirectCallback = async (appstate: any, user: any) => {
    setTimeout(function () {
      if (user && user.email) {
        UserService.updateLastLoginDate(user.email);
      }
    }, 1000);
  };

  return (
    <>
      {maintenance_mode ? (
        <Maintenance />
      ) : (
        <Suspense fallback={<div />}>
          <Provider store={store}>
            <ThemeProvider theme={customTheme}>
              {
                <Auth0ProviderWithRedirectCallback
                  onRedirectCallback={onRedirectCallback}
                  useRefreshTokensFallback={true}
                  useRefreshTokens={true}
                  cacheLocation="localstorage"
                  domain={auth0domain}
                  clientId={auth0clientid}
                  authorizationParams={{
                    redirect_uri: auth0RedirectUri,
                    audience: auth0Audience,
                    scope: 'openid profile email offline_access'
                  }}>
                  <Router history={history}>
                    <Dashboard />
                  </Router>
                  <SessionTimeout />
                </Auth0ProviderWithRedirectCallback>
              }
            </ThemeProvider>
          </Provider>
        </Suspense>
      )}
    </>
  );
};

export default App;
