import { FC } from 'react';
import { Box, Typography } from '@mui/material';
import FileIcon from '@mui/icons-material/Description';
import CloseIcon from '@mui/icons-material/Close';

interface UploadedFileConfirmationProps {
  handleDeleteFile: () => void;
  file: any;
}

const UploadedFileConfirmation: FC<UploadedFileConfirmationProps> = ({
  file,
  handleDeleteFile
}): JSX.Element => {
  function readableBytes(bytes: number) {
    const i = Math.floor(Math.log(bytes) / Math.log(1024)),
      sizes = ['Bytes', 'KB', 'MB'];
    return (bytes / Math.pow(1024, i)).toFixed(1) + ' ' + sizes[i];
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center'
        }}>
        <FileIcon
          sx={{
            fontSize: '14px',
            lineHeight: '22px'
          }}
        />
        <Typography
          sx={{
            pl: '6px',
            display: 'inline',
            fontSize: '14px',
            lineHeight: '22px'
          }}>
          {file.name}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center'
        }}>
        <Typography
          sx={{
            display: 'inline',
            fontSize: '12px',
            lineHeight: '16px'
          }}>
          {readableBytes(file.size)}
        </Typography>
        <CloseIcon
          onClick={handleDeleteFile}
          sx={{
            display: 'inline',
            fontSize: '16px',
            lineHeight: '22px',
            pl: '24px'
          }}
        />
      </Box>
    </>
  );
};

export default UploadedFileConfirmation;
