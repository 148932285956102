import React, { FC } from 'react';
import { TextField, Box, styled, Typography } from '@mui/material';
import { useAppSelector } from '../shared/redux/hooks';
import { RootState } from '../shared/redux/store';

const StyledTextField = styled(TextField)(() => ({
  '& .MuiFilledInput-root': {
    backgroundColor: '#F6F6FA',
    borderRadius: '0px',
    '&:hover': {
      backgroundColor: '#F6F6FA'
    }
  },
  '& .Mui-focused': {
    backgroundColor: '#F6F6FA'
  },
  '& .MuiFormLabel-root': {
    fontSize: '14px',
    fontWeight: '300px',
    color: '#000'
  }
}));
interface BlockHeightInputFieldProps {
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  blockHeightInputValue: string;
  blockHeightError: boolean;
  network?: string;
}

const BlockHeightInputField: FC<BlockHeightInputFieldProps> = ({
  handleInputChange,
  blockHeightInputValue,
  blockHeightError
}): JSX.Element => {
  const latestBlockHeight = useAppSelector((state: RootState) => state.cryptoSlice);
  return (
    <Box sx={{ paddingTop: '24px' }}>
      <StyledTextField
        id="filled-basic"
        type="text"
        label="Block height (required)"
        variant="filled"
        value={blockHeightInputValue}
        onChange={handleInputChange}
        fullWidth
        size="small"
        InputLabelProps={{
          shrink: true
        }}
        error={blockHeightError}
        placeholder={`Last block: ${
          latestBlockHeight.pending ? '' : latestBlockHeight.value.last_ingested_block || ''
        }`}
        autoComplete="off"
      />
      {blockHeightError && (
        <Typography sx={{ fontSize: '11px', color: '#d32f2f' }}>
          Block height must be less than or equal to {latestBlockHeight.value.last_ingested_block}
        </Typography>
      )}
    </Box>
  );
};

export default BlockHeightInputField;
