/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-global-assign */
import Cookies from 'js-cookie';
import { createBrowserHistory } from 'history';

interface CookieConfiguration {
  name: string;
  expiry: number;
}

interface CookieDTO {
  access_token?: string;
  user_id?: string;
  first_name?: string;
  last_name?: string;
}
const cookieConfig: CookieConfiguration = {
  name: 'fdas_poc',
  expiry: 7
};

export const checkBlockHeightInputValue = (BlockHeight: string) => {
  const numberRegex = /^\d+$/;
  return numberRegex.test(BlockHeight);
};

export function clearCookies() {
  Cookies.remove(cookieConfig.name);
}

export function setCookies(data: CookieDTO) {
  try {
    const cookie: CookieDTO = {};
    if (data.access_token) cookie.access_token = data.access_token;
    if (data.user_id) cookie.user_id = data.user_id;
    if (data.first_name) cookie.first_name = data.first_name;
    if (data.last_name) cookie.last_name = data.last_name;

    Cookies.set(cookieConfig.name, JSON.stringify(data), { expires: cookieConfig.expiry });
  } catch (e) {
    console.error(e);
  }
}

export function getCookie() {
  if (Cookies.get(cookieConfig.name)) {
    const cookie: CookieDTO = JSON.parse(Cookies.get(cookieConfig.name));
    if (cookie) return cookie;
  }
  return false;
}
export function isAuthenticated() {
  if (Cookies.get(cookieConfig.name)) {
    const cookie: CookieDTO = JSON.parse(Cookies.get(cookieConfig.name));
    if (cookie) return cookie.access_token;
  }
  return false;
}

export const history: any = () => createBrowserHistory();

export const stringAvatar = (name: string) => {
  return {
    sx: {
      bgcolor: '#FF9831'
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`
  };
};
export function updateCookie(data: any) {
  const cookie: CookieDTO = Cookies.get(cookieConfig.name);
  if (data.access_token) cookie.access_token = data.access_token;
  if (data.user_id) cookie.user_id = data.user_id;
  Cookies.set(cookieConfig.name, cookie, { expires: cookieConfig.expiry });
}
