import React, { FC, useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import DownArrowIcon from '@mui/icons-material/ArrowDropDown';
import { fileService } from '../shared/services/file';
import { useDispatch } from 'react-redux';

interface DownloadOutputOptionsProps {
  standardOutputParams: any;
  aggregateOutputParams: any;
}

const DownloadOutputOptions: FC<DownloadOutputOptionsProps> = ({
  standardOutputParams,
  aggregateOutputParams
}): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const dispatch = useDispatch();
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{
          minWidth: '30px',
          marginTop: '9px',
          right: 10,
          height: '36px',
          backgroundColor: 'white',
          border: '1px solid black',
          borderRadius: '0px',
          '&:hover': {
            background: 'black',
            color: 'white'
          }
        }}>
        <DownArrowIcon />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}>
        <MenuItem
          onClick={() => fileService.blobDownload(aggregateOutputParams, dispatch)}
          sx={{ fontSize: '14px' }}>
          Aggregated Output file
        </MenuItem>
        <MenuItem
          onClick={() => fileService.blobDownload(standardOutputParams, dispatch)}
          sx={{ fontSize: '14px' }}>
          Standard Output file
        </MenuItem>
      </Menu>
    </div>
  );
};

export default DownloadOutputOptions;
