export interface Jobs {
  job_id: string;
  run_id: number;
  file_name: string;
  uploaded_file_path: string;
  block_height: number;
  network_name: string;
  upload_datetime: Date;
  overall_status: OverAllStatus;
  address_extracted: number;
  output_filepath: string;
  aggregated_output_filepath: string;
  validation_error_filepath: string;
}
export enum OverAllStatus {
  INVALID_TEMPLATE,
  INVALID_CONTENT,
  INPROGRESS,
  COMPLETED,
  FAILED,
  EMPTY_FILE
}
