import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { ResponseError } from '../../core/IError';
import { block } from '../../services/crypto';
import { ILatestBlockHeight } from '../../models/Crypto';

interface ICryptoSliceState {
  value: ILatestBlockHeight;
  pending: boolean;
  error?: string;
}

const initialState: ICryptoSliceState = {
  value: { last_ingested_block: null },
  pending: true,
  error: ''
};

export const getLatestBlockHeight = createAsyncThunk<
  any | string,
  string,
  { rejectValue: ResponseError }
>('/getlatestblockheight', async (network, thunkApi) => {
  const response = await block.getLatestBlockHeight(network);
  if (response.isSuccess) {
    return response.getValue();
  }
  return thunkApi.rejectWithValue({ message: response.errorValue().toString() });
});

export const cryptoSlice = createSlice({
  name: 'cryptoSlice',
  initialState,
  reducers: {},
  extraReducers: (reducers) => {
    reducers
      .addCase(getLatestBlockHeight.pending, (state) => {
        state.pending = true;
      })
      .addCase(getLatestBlockHeight.rejected, (state, { payload }) => {
        state.error = payload?.message.toString();
        state.pending = false;
      })
      .addCase(getLatestBlockHeight.fulfilled, (state, { payload }) => {
        state.pending = false;
        state.value = payload;
      });
  }
});

export const selectCryptoSlice = (state: RootState) => {
  return state.cryptoSlice;
};

// export the reducers
export default cryptoSlice.reducer;
