import { FC } from 'react';
import { Box, Typography } from '@mui/material';

const NoTableData: FC = (): JSX.Element => {
  return (
    <Box
      sx={{
        mt: '8px',
        display: 'flex',
        backgroundColor: '#F6F6FA',
        height: '189px',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
      <Typography
        sx={{
          fontSize: '12px',
          fontWeight: '300',
          color: '#747480',
          lineHeight: '20px'
        }}>
        No data
      </Typography>
    </Box>
  );
};
export default NoTableData;
