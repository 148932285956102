import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { clearCookies } from '../shared/utils';

const SessionTimeout: React.FC = () => {
  const { logout } = useAuth0();
  const sessionTimeOutInSec = (window as any)['platformConfig']?.SessionTimeOut;
  const inactivityTimeoutInMinutes = sessionTimeOutInSec
    ? parseInt(sessionTimeOutInSec) * 1000
    : 30 * 60 * 1000;
  useEffect(() => {
    let inactivityTimer: NodeJS.Timeout;

    const resetInactivityTimer = () => {
      clearTimeout(inactivityTimer);
      inactivityTimer = setTimeout(() => {
        // User has been inactive for the specified time, log them out
        clearCookies();
        logout({ logoutParams: { returnTo: window.location.origin } });
      }, inactivityTimeoutInMinutes);
    };

    const handleUserActivity = () => {
      resetInactivityTimer();
    };

    // Initial setup of the inactivity timer
    resetInactivityTimer();

    // Listen for user activity events (e.g., mousemove, keydown)
    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('keydown', handleUserActivity);

    return () => {
      // Cleanup: remove event listeners and clear the inactivity timer
      clearTimeout(inactivityTimer);
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('keydown', handleUserActivity);
    };
  }, [logout, inactivityTimeoutInMinutes]);

  return null;
};

export default SessionTimeout;
