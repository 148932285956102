import React, { FC, useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import BlockHeightInputField from '../components/BlockHeightInputField';
import DeriveAddressesButton from '../components/DeriveAddressesButton';
import DragAndDrop from '../components/DragAndDrop';
import DerivationTable from '../components/DerivationTable';
import { checkBlockHeightInputValue } from '../shared/utils';
import { useAppDispatch, useAppSelector } from '../shared/redux/hooks';
import { deriveAddress } from '../shared/redux/slice/fileSlice';
import { getLatestBlockHeight } from '../shared/redux/slice/cryptoSlice';
import { SUPPORTED_ASSETS } from '../shared/helpers/constants';
import JobsSearchBar from '../components/JobsSearchBar';
import { RootState } from '../shared/redux/store';

interface EthereumAddressDerivationProps {
  isBTCTabClicked: boolean;
  isETHTabClicked: boolean;
}

const EthereumAddressDerivation: FC<EthereumAddressDerivationProps> = ({
  isBTCTabClicked,
  isETHTabClicked
}): JSX.Element => {
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
  const [blockHeightInputValue, setBlockHeightInputValue] = useState<string>('');
  const [file, setFile] = useState<any>(null);
  const [isSortButtonClicked, setIsSortButtonClicked] = useState<boolean>(false);
  const [jobsSearchValue, setJobsSearchValue] = useState<string>('');

  const dispatch = useAppDispatch();
  const [blockHeightError, setBlockHeightError] = useState<boolean>(false);

  useEffect(() => {
    dispatch(getLatestBlockHeight(SUPPORTED_ASSETS.ETH));
  }, [dispatch]);

  useEffect(
    () => setIsButtonDisabled(!file || blockHeightInputValue === '' || blockHeightError),
    [file, blockHeightError, blockHeightInputValue]
  );
  const latestBlockHeight = useAppSelector((state: RootState) => state.cryptoSlice);
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (checkBlockHeightInputValue(e.target.value) || e.target.value === '') {
      setBlockHeightInputValue(e.target.value);
      if (
        !latestBlockHeight.pending &&
        Number(e.target.value) > Number(latestBlockHeight.value.last_ingested_block)
      ) {
        setBlockHeightError(true);
      } else {
        setBlockHeightError(false);
      }
    }
  };

  const handleJobsSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setJobsSearchValue(e.target.value);
  };

  const handleFileUpload = (file: any) => setFile(file);

  const handleDeleteFile = () => {
    setIsButtonDisabled(true);
    setFile(null);
  };

  const handleAddressDerivation = () => {
    dispatch(
      deriveAddress({
        assetName: SUPPORTED_ASSETS.ETH,
        blockHeight: parseInt(blockHeightInputValue),
        csv: file
      })
    );
    setIsSortButtonClicked(false);
    setFile(null);
    setBlockHeightInputValue('');
    setIsButtonDisabled(true);
  };

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-around',
          paddingTop: '24px',
          paddingBottom: '30px'
        }}>
        <Box
          sx={{
            width: '660px'
          }}>
          <Typography
            data-cy="heading"
            sx={{
              paddingBottom: '24px',
              fontSize: '20px',
              lineHeight: '24px',
              fontWeight: '400px'
            }}>
            Derive Ethereum addresses from XPubs
          </Typography>

          <DragAndDrop
            handleFileUpload={handleFileUpload}
            handleDeleteFile={handleDeleteFile}
            file={file}
            isBTCTabClicked={isBTCTabClicked}
            isETHTabClicked={isETHTabClicked}
          />

          <BlockHeightInputField
            blockHeightError={blockHeightError}
            handleInputChange={handleInputChange}
            blockHeightInputValue={blockHeightInputValue}
          />

          <DeriveAddressesButton
            disabled={isButtonDisabled}
            handleAddressDerivation={handleAddressDerivation}
          />
        </Box>
      </Box>
      <JobsSearchBar
        jobsSearchValue={jobsSearchValue}
        handleJobsSearchInputChange={handleJobsSearchInputChange}
      />
      <DerivationTable
        network={SUPPORTED_ASSETS.ETH}
        handleFileUpload={handleFileUpload}
        isSortButtonClicked={isSortButtonClicked}
        setIsSortButtonClicked={setIsSortButtonClicked}
        jobsSearchValue={jobsSearchValue}
      />
    </Box>
  );
};

export default EthereumAddressDerivation;
