import React, { FC } from 'react';
import { TextField, Box, styled, Typography, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const StyledTextField = styled(TextField)(() => ({
  '& .MuiFilledInput-root': {
    backgroundColor: '#F6F6FA',
    borderRadius: '0px',
    height: '45px',
    width: '380px',
    '&:hover': {
      backgroundColor: '#F6F6FA'
    }
  },
  '& .Mui-focused': {
    backgroundColor: '#F6F6FA'
  },
  '& .MuiFormLabel-root': {
    fontSize: '14px',
    fontWeight: '300px',
    color: '#000'
  }
}));

interface JobsSearchBarProps {
  jobsSearchValue: string;
  handleJobsSearchInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const JobsSearchBar: FC<JobsSearchBarProps> = ({
  jobsSearchValue,
  handleJobsSearchInputChange
}) => {
  return (
    <Box
      sx={{
        borderTop: '1px solid #E0E0E0',
        borderBottom: '1px solid #E0E0E0',
        display: 'flex',
        justifyContent: 'space-between',
        margin: '40px 24px 0 24px'
      }}>
      <Typography sx={{ margin: '12px 0 0 5px', fontWeight: 'bold' }}>Job List</Typography>
      <Box
        sx={{
          textAlign: 'right'
        }}>
        <StyledTextField
          id="filled-basic"
          type="text"
          label="Search"
          variant="filled"
          InputLabelProps={{
            shrink: true
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon sx={{ color: 'black' }} />
              </InputAdornment>
            )
          }}
          value={jobsSearchValue}
          onChange={handleJobsSearchInputChange}
        />
      </Box>
    </Box>
  );
};

export default JobsSearchBar;
