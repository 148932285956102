import { createSlice } from '@reduxjs/toolkit';

interface LoadingState {
  isLoading: boolean;
}

// Define the initial state
const initialState: LoadingState = {
  isLoading: false // Default loading state
};

// Create the slice
const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true; // Set loading to true
    },
    stopLoading: (state) => {
      state.isLoading = false; // Set loading to false
    }
  }
});

// Export the actions
export const { startLoading, stopLoading } = loadingSlice.actions;

// Export the reducer
export default loadingSlice.reducer;
