import { Api as api } from '../../utils/axios';
import { IUsersService } from './IUsersService';

export class UsersService implements IUsersService {
  async register(newUser): Promise<any> {
    const response = await api({
      'Content-Type': 'application/json'
    }).post('/users/registration', newUser);
    return response;
  }

  async login(user): Promise<any> {
    const response = await api({
      'Content-Type': 'application/json'
    }).post('/users/login', user);
    return response;
  }
  async checkAccess(): Promise<any> {
    const response = await api({
      'Content-Type': 'application/json'
    }).get('/users/check-access');
    return response;
  }
  async updateLastLoginDate(email: string): Promise<any> {
    const response = await api({
      'Content-Type': 'application/json'
    }).put('/users/last-login', { email: email });
    return response;
  }
  async logout(accessToken: string): Promise<any> {
    const response = await api({
      'Content-Type': 'application/json'
    }).post('/users/logout', { token: accessToken });
    return response;
  }
}
