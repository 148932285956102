import React, { FC } from 'react';

const AccessDenied: FC = (): JSX.Element => {
  return (
    <div
      style={{
        backgroundColor: 'white',
        marginTop: '10%',
        display: 'grid',
        placeItems: 'center'
      }}>
      <p style={{ fontWeight: 'bold' }}>Access Restricted</p>
      <p>
        You do not have access to perform Address Derivation. Kindly contact your firm’s
        representative to request adding your email to the whitelist.
      </p>
    </div>
  );
};

export default AccessDenied;
