import axios, { AxiosInstance } from 'axios';
import io from 'socket.io-client';
import { isAuthenticated, clearCookies } from '../utils';

const REACT_APP_ApiUrl: string = (window as any)['platformConfig']?.REACT_APP_ApiUrl;

export const Api: any = (headers: any = {}): AxiosInstance | any => {
  try {
    const accesstoken = isAuthenticated();
    if (!accesstoken) {
      return Error('Access token is empty');
    }
    const opts: any = {
      baseURL: REACT_APP_ApiUrl.trim(),
      headers: Object.assign(
        {
          Authorization: `Bearer ${accesstoken}` || undefined
        },
        headers
      ),
      validateStatus: (status: any) => status >= 200 && status < 204
    };
    const axiosInstance = axios.create(opts);

    axiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        console.log('Error axios', error);
        if (accesstoken && error.response && error.response.status === 401) {
          clearCookies();
          window.location.href = '/';
        } else {
          throw error;
        }
      }
    );

    return axiosInstance;
  } catch (error) {
    console.log(error);
  }
};

export const catchHandler = (history: any, error: any) => {
  console.log(error, history);
  if (isAuthenticated() && error.response && error.response.status === 401) {
    clearCookies();
    history.push('/login');
  }
};
export const socket = io(REACT_APP_ApiUrl, {
  transports: ['websocket']
});
