import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { Jobs } from '../../models/Jobs';
import { ResponseError } from '../../core/IError';
import { jobService } from '../../services/jobs';

interface IJobsSliceState {
  jobs: Jobs[];
  pending: boolean;
  error?: string;
}

const initialState: IJobsSliceState = {
  jobs: [],
  pending: true,
  error: ''
};

export const fetchJobList = createAsyncThunk<any | Jobs[], void, { rejectValue: ResponseError }>(
  '/jobs/fetchAll',
  async (_, thunkApi) => {
    const response = await jobService.getAllJobs();
    if (response.isSuccess) {
      return response.getValue();
    }
    return thunkApi.rejectWithValue({ message: response.errorValue().toString() });
  }
);

export const jobSlice = createSlice({
  name: 'jobSlice',
  initialState,
  reducers: {
    updateJobStatus: (state, action) => ({ ...state, jobs: action.payload })
  },
  extraReducers: (reducers) => {
    reducers
      .addCase(fetchJobList.pending, (state) => {
        state.pending = true;
      })
      .addCase(fetchJobList.rejected, (state, { payload }) => {
        state.error = payload?.message.toString();
        state.pending = false;
      })
      .addCase(fetchJobList.fulfilled, (state, { payload }) => {
        state.pending = false;
        state.jobs = payload;
      });
  }
});

export const selectJobSlice = (state: RootState) => {
  return state.jobSlice;
};

// export the reducers
export default jobSlice.reducer;
