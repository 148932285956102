import { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { FileUploader } from 'react-drag-drop-files';
import UploadedFileConfirmation from './UploadedFileConfirmation';
import DownloadTemplateButton from './DownloadTemplateButton';
import { Feed } from '../images/icon';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const fileTypes = ['CSV'];

interface DragAndDropProps {
  file: null | any;
  handleFileUpload: (file: any) => void;
  handleDeleteFile: () => void;
  isBTCTabClicked: boolean;
  isETHTabClicked: boolean;
}

const DragAndDrop: FC<DragAndDropProps> = ({
  file,
  handleFileUpload,
  handleDeleteFile,
  isBTCTabClicked,
  isETHTabClicked
}): JSX.Element => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          position: 'relative',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          height: '240px',
          backgroundColor: '#F6F6FA',
          border: '1px dashed #C4C4CD'
        }}>
        <DownloadTemplateButton
          isBTCTabClicked={isBTCTabClicked}
          isETHTabClicked={isETHTabClicked}
        />
        <Box sx={{ position: 'absolute', top: '18px', left: '20px', display: 'flex' }}>
          <Feed />
          <Typography sx={{ fontSize: '12px', pl: '4px' }}>CSV files only</Typography>
        </Box>
        <FileUploader
          handleChange={handleFileUpload}
          name="file"
          types={fileTypes}
          fileOrFiles={file}
          maxSize={500}>
          <Box sx={{ textAlign: 'center', cursor: 'pointer' }}>
            <CloudUploadIcon sx={{ fontSize: '36px' }} />
            <Typography sx={{ fontSize: '14px' }}>Drag and drop a document or browse</Typography>
            <Typography sx={{ fontSize: '12px', color: '#747480' }}>
              Max size: 500MB Format: .csv
            </Typography>
          </Box>
        </FileUploader>
      </Box>
      <Box
        sx={
          file
            ? {
                marginTop: '8px',
                fontWeight: '400',
                backgroundColor: '#FAFAFC',
                border: '1px solid #E7E7EA',
                display: 'flex',
                justifyContent: 'space-between',
                p: '14px'
              }
            : {
                display: 'none'
              }
        }>
        {file && <UploadedFileConfirmation handleDeleteFile={handleDeleteFile} file={file} />}
      </Box>
    </>
  );
};

export default DragAndDrop;
