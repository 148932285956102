import { FC } from 'react';
import { Button } from '@mui/material';
import DownloadIcon from '@mui/icons-material/GetApp';
import { Api as api } from '../shared/utils/axios';
import { SUPPORTED_ASSETS } from '../shared/helpers/constants';
import { useDispatch } from 'react-redux';
import { startLoading, stopLoading } from '../shared/redux/slice/loadingSlice';
interface DownloadTemplateButtonProps {
  isBTCTabClicked: boolean;
  isETHTabClicked: boolean;
}

const DownloadTemplateButton: FC<DownloadTemplateButtonProps> = ({
  isBTCTabClicked,
  isETHTabClicked
}): JSX.Element => {
  const btcNetworkName = SUPPORTED_ASSETS.BTC;
  const ethNetworkName = SUPPORTED_ASSETS.ETH;
  const ltcNetworkName = SUPPORTED_ASSETS.LTC;
  const dispatch = useDispatch();
  const downloadTemplate = async () => {
    dispatch(startLoading());
    await api()
      .get(
        `/file/download?fileType=templateFile&networkName=${
          isBTCTabClicked ? btcNetworkName : isETHTabClicked ? ethNetworkName : ltcNetworkName
        }`
      )
      .then((response: any) => {
        const a = document.createElement('a');
        a.style.display = 'none';
        document.body.appendChild(a);
        const blobFile = new Blob([response?.data], { type: 'text/csv' });
        dispatch(stopLoading());
        const url = window.URL.createObjectURL(blobFile);
        a.href = url;
        a.download = `${
          isBTCTabClicked ? btcNetworkName : isETHTabClicked ? ethNetworkName : ltcNetworkName
        }_address-derivation_template.csv`;
        a.click();
        window.URL.revokeObjectURL(url);
      });
  };
  return (
    <Button
      onClick={downloadTemplate}
      sx={{
        width: '203px',
        height: '36px',
        backgroundColor: '#FFFFFF',
        color: '#2E2E38',
        fontWeight: 'bold',
        border: '1px solid #2E2E38',
        textTransform: 'none',
        fontSize: '12px',
        borderRadius: '0px',
        padding: '7px 30px 7px 26px',
        position: 'absolute',
        top: '16px',
        right: '16px',
        '&:hover': {
          background: 'black',
          color: 'white'
        }
      }}>
      <DownloadIcon sx={{ marginRight: '7px', width: '18px' }} />
      Download template
    </Button>
  );
};

export default DownloadTemplateButton;
