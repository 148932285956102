import { FC, SetStateAction, useCallback, useEffect, useState } from 'react';
import {
  TableContainer,
  TableHead,
  Table,
  TableRow as RowOfTable,
  TableCell,
  TableBody
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import TableRow from './TableRow';
import NoTableData from './noTableData';
import { useAppSelector, useAppDispatch } from '../shared/redux/hooks';
import { fetchJobList } from '../shared/redux/slice/jobSlice';
import { RootState } from '../shared/redux/store';
import { socket } from '../shared/utils/axios';
import { SUPPORTED_ASSETS_NETWORK_NAME } from '../shared/helpers/constants';
import { OverAllStatus } from '../shared/models/Jobs';

const StyledTableHeader = styled(TableCell)(() => ({
  fontWeight: 'bold',
  fontSize: '12px',
  position: 'relative',
  padding: '2px'
}));

const UpArrowIcon = styled(ArrowDropUp)(() => ({
  position: 'absolute',
  top: -1,
  cursor: 'pointer',
  width: '18px'
}));

const DownArrowIcon = styled(ArrowDropDown)(() => ({
  position: 'absolute',
  top: 10,
  cursor: 'pointer',
  width: '18px'
}));

const UpDownIconSpan = styled('span')(() => ({
  marginLeft: '2px'
}));

interface DerivationTableProps {
  network: string;
  handleFileUpload: (file: any) => void;
  isSortButtonClicked: boolean;
  setIsSortButtonClicked: React.Dispatch<SetStateAction<boolean>>;
  jobsSearchValue: string;
}

const DerivationTable: FC<DerivationTableProps> = ({
  network,
  handleFileUpload,
  isSortButtonClicked,
  setIsSortButtonClicked,
  jobsSearchValue
}): JSX.Element => {
  const [sortedAllJobsData, setSortedAllJobsData] = useState<any>([]);

  const dispatch = useAppDispatch();
  const callFetchJobList = useCallback(() => {
    return dispatch(fetchJobList());
  }, [dispatch]);

  useEffect(() => {
    callFetchJobList();
    socket.on('connect_error', (err) => console.log('WebSocket Error :', err));
    socket.on('jobStatus', callFetchJobList);
    socket.on('newJob', callFetchJobList);
    return () => {
      socket.off('connect_error');
      socket.off('jobStatus');
      socket.off('newJob');
    };
  }, [dispatch, callFetchJobList]);

  const allJobsDataResponse: any = useAppSelector((state: RootState) => state.jobSlice);
  const btcAllJobsData = [...(allJobsDataResponse.jobs || [])].filter(
    (data) => data.network_name === network
  );
  const handleSortByJobID = () => {
    const sortedArray = btcAllJobsData.sort((a, b) => a.job_id.localeCompare(b.job_id));
    setSortedAllJobsData(sortedArray);
    setIsSortButtonClicked(true);
    return sortedArray;
  };

  const handleSortByJobIDReversed = () => {
    const sortedArray = btcAllJobsData.sort((a, b) => b.job_id.localeCompare(a.job_id));
    setSortedAllJobsData(sortedArray);
    setIsSortButtonClicked(true);
    return sortedArray;
  };

  const handleSortByFileName = () => {
    const sortedArray = btcAllJobsData.sort((a, b) => a.file_name.localeCompare(b.file_name));
    setSortedAllJobsData(sortedArray);
    setIsSortButtonClicked(true);
    return sortedArray;
  };

  const handleSortByFileNameReversed = () => {
    const sortedArray = btcAllJobsData.sort((a, b) => b.file_name.localeCompare(a.file_name));
    setSortedAllJobsData(sortedArray);
    setIsSortButtonClicked(true);
    return sortedArray;
  };

  const handleSortByBlockHeight = () => {
    const sortedArray = btcAllJobsData.sort((a, b) => a.block_height - b.block_height);
    setSortedAllJobsData(sortedArray);
    setIsSortButtonClicked(true);
    return sortedArray;
  };

  const handleSortByBlockHeightReversed = () => {
    const sortedArray = btcAllJobsData.sort((a, b) => b.block_height - a.block_height);
    setSortedAllJobsData(sortedArray);
    setIsSortButtonClicked(true);
    return sortedArray;
  };

  const handleSortByWalletCount = () => {
    const sortedArray = btcAllJobsData.sort((a, b) => b.address_extracted - a.address_extracted);
    setSortedAllJobsData(sortedArray);
    setIsSortButtonClicked(true);
    return sortedArray;
  };

  const handleSortByWalletCountReversed = () => {
    const sortedArray = btcAllJobsData.sort((a, b) => a.address_extracted - b.address_extracted);
    setSortedAllJobsData(sortedArray);
    setIsSortButtonClicked(true);
    return sortedArray;
  };

  const handleSortByStatus = () => {
    const sortedArray = btcAllJobsData.sort((a, b) => b.overall_status - a.overall_status);
    setSortedAllJobsData(sortedArray);
    setIsSortButtonClicked(true);
    return sortedArray;
  };

  const handleSortByStatusReversed = () => {
    const sortedArray = btcAllJobsData.sort((a, b) => a.overall_status - b.overall_status);
    setSortedAllJobsData(sortedArray);
    setIsSortButtonClicked(true);
    return sortedArray;
  };

  const handleSortByUploadDateTime = () => {
    const sortedArray = btcAllJobsData.sort((a, b) => {
      const firstDate: any = new Date(b.upload_datetime);
      const secondDate: any = new Date(a.upload_datetime);
      return firstDate - secondDate;
    });
    setSortedAllJobsData(sortedArray);
    setIsSortButtonClicked(true);
    return sortedArray;
  };

  const handleSortByUploadDateTimeReversed = () => {
    const sortedArray = btcAllJobsData.sort((a, b) => {
      const firstDate: any = new Date(b.upload_datetime);
      const secondDate: any = new Date(a.upload_datetime);
      return secondDate - firstDate;
    });
    setSortedAllJobsData(sortedArray);
    setIsSortButtonClicked(true);
    return sortedArray;
  };

  const prepareJobsList = (listOfJobs): Array<any> => {
    // eslint-disable-next-line
    return listOfJobs.filter((item: any) => {
      const overallStatus =
        item.overall_status === OverAllStatus.INVALID_TEMPLATE
          ? 'Invalid template'
          : item.overall_status === OverAllStatus.INVALID_CONTENT
          ? 'Invalid content'
          : item.overall_status === OverAllStatus.INPROGRESS
          ? 'In progress'
          : item.overall_status === OverAllStatus.COMPLETED
          ? 'Complete'
          : item.overall_status === OverAllStatus.EMPTY_FILE
          ? 'Empty Input file'
          : item.overall_status === OverAllStatus.FAILED
          ? 'Failed'
          : '';
      const networkName = SUPPORTED_ASSETS_NETWORK_NAME[item.network_name];
      const dateAsString = new Date(item.upload_datetime).toString();
      const timeZone = dateAsString.substring(
        dateAsString.indexOf(')'),
        dateAsString.lastIndexOf('(') + 1
      );
      const cutTimeZone = timeZone
        .split(' ')
        .map((str: string) => str.charAt(0))
        .join('');
      const cutMonth = dateAsString.slice(4, 7);
      const cutDay = dateAsString.slice(8, 10);
      const cutYearAndTime = dateAsString.slice(13, 24);
      const formattedDateWithTimezone = `${cutDay} ${cutMonth} ${cutYearAndTime} ${cutTimeZone}`;
      if (jobsSearchValue === '') {
        return btcAllJobsData;
      } else if (
        // search by job id
        item.job_id.toLowerCase().includes(jobsSearchValue.toLowerCase()) ||
        // search by file name
        item.file_name.toLowerCase().includes(jobsSearchValue.toLowerCase()) ||
        // search by network name
        networkName.toLowerCase().includes(jobsSearchValue.toLowerCase()) ||
        // search by asset name
        item.network_name.toLowerCase().includes(jobsSearchValue.toLowerCase()) ||
        // search by date and time
        formattedDateWithTimezone.toLowerCase().includes(jobsSearchValue.toLowerCase()) ||
        // search by block height
        item.block_height.toString().toLowerCase().includes(jobsSearchValue.toLowerCase()) ||
        // search by wallet count
        (item.wallet_extracted &&
          item.wallet_extracted.toString().toLowerCase().includes(jobsSearchValue.toLowerCase())) ||
        // search by status
        overallStatus.toString().toLowerCase().includes(jobsSearchValue.toLowerCase())
      ) {
        return item;
      }
    });
  };

  return (
    <TableContainer sx={{ margin: '20px 24px 0px 24px', width: 'auto' }}>
      <Table>
        <TableHead>
          <RowOfTable>
            <StyledTableHeader align="left">
              Job ID
              <UpArrowIcon onClick={handleSortByJobID} fontSize="small" />
              <DownArrowIcon onClick={handleSortByJobIDReversed} fontSize="small" />
            </StyledTableHeader>
            <StyledTableHeader align="left">
              File Name
              <UpDownIconSpan>
                <UpArrowIcon onClick={handleSortByFileName} fontSize="small" />
                <DownArrowIcon onClick={handleSortByFileNameReversed} fontSize="small" />
              </UpDownIconSpan>
            </StyledTableHeader>
            <StyledTableHeader align="left">Network Name</StyledTableHeader>
            <StyledTableHeader align="left">Asset Name</StyledTableHeader>
            <StyledTableHeader align="left">
              Upload Date Time
              <UpDownIconSpan>
                <UpArrowIcon onClick={handleSortByUploadDateTime} fontSize="small" />
                <DownArrowIcon onClick={handleSortByUploadDateTimeReversed} fontSize="small" />
              </UpDownIconSpan>
            </StyledTableHeader>
            <StyledTableHeader align="left">
              Block Height
              <UpDownIconSpan>
                <UpArrowIcon onClick={handleSortByBlockHeight} fontSize="small" />
                <DownArrowIcon onClick={handleSortByBlockHeightReversed} fontSize="small" />
              </UpDownIconSpan>
            </StyledTableHeader>
            <StyledTableHeader align="left">
              Wallet Count
              <UpDownIconSpan>
                <UpArrowIcon onClick={handleSortByWalletCount} fontSize="small" />
                <DownArrowIcon onClick={handleSortByWalletCountReversed} fontSize="small" />
              </UpDownIconSpan>
            </StyledTableHeader>
            <StyledTableHeader align="left">
              Status
              <UpDownIconSpan>
                <UpArrowIcon onClick={handleSortByStatus} fontSize="small" />
                <DownArrowIcon onClick={handleSortByStatusReversed} fontSize="small" />
              </UpDownIconSpan>
            </StyledTableHeader>
            <StyledTableHeader align="right">Actions</StyledTableHeader>
          </RowOfTable>
        </TableHead>
        {Boolean(btcAllJobsData.length) && (
          <TableBody>
            {prepareJobsList(isSortButtonClicked ? sortedAllJobsData : btcAllJobsData).map(
              (row: any) => (
                <TableRow
                  key={row.job_id}
                  jobId={row.job_id}
                  file={row.file_name}
                  uploadedFilePath={row.uploaded_file_path}
                  networkName={row.network_name}
                  uploadDateTime={row.upload_datetime}
                  blockHeight={row.block_height}
                  walletCount={row.wallet_extracted}
                  status={row.overall_status}
                  standardOutputPath={row.output_filepath}
                  aggregateOutputPath={row.aggregated_output_filepath}
                  errorOutputPath={row.validation_error_filepath}
                  handleFileUpload={handleFileUpload}
                />
              )
            )}
          </TableBody>
        )}
      </Table>
      {(Boolean(!btcAllJobsData.length) ||
        prepareJobsList(isSortButtonClicked ? sortedAllJobsData : btcAllJobsData).length <= 0) && (
        <NoTableData />
      )}
    </TableContainer>
  );
};

export default DerivationTable;
